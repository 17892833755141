

import {IUserServicePriceResponse} from '@/services/dto/user/IUserServicePriceResponse';

import {Component, Ref} from 'vue-property-decorator';
import SigStepNav from '@/components/user/SigStepNav.vue';
import UserCreationStep3 from '@/components/user/UserCreationStep3.vue';
import UserCreationStep2 from '@/components/user/UserCreationStep2.vue';
import {services} from '@/main';
import {INavigationStep} from '@/services/components/INavigationStep';
import UserCreationService from '@/services/UserCreationService';
import UserCard from '@/components/UserCard.vue';
import {ICreateMailServiceRequest} from '@/services/dto/user-service/ICreateMailServiceRequest';
import AlertService from '@/utils/AlertService';
import UserServicesUtils from '@/services/UserServicesUtils';
import AbstractComponentVue from '@/services/AbstractComponentVue';
import {IEmailCreation} from '@/services/dto/user/ICreateUser';
import {IUserResponse} from '@/services/dto/IUserResponse';
import LoginUtils from '@/utils/LoginUtils';
import UserUtils from '@/utils/UserUtils';

const userCreationService = new UserCreationService();
const userServicesUtils = new UserServicesUtils();

@Component({
  components: {
    UserCreationStep3,
    UserCard,
    UserCreationStep2,
    SigStepNav,
  },
})
export default class MailActivation extends AbstractComponentVue {

  // Creation des donnés de navigation
  private navSteps: INavigationStep = userCreationService.initNav();
  // Identifiant de compte en cours de modification
  private accountId: number = +this.$route.params.id;
  private userSrpDto?: IUserServicePriceResponse;
  private entitySelected: boolean = false;
  @Ref('step2ForMailSelection') private userCreationStep2!: UserCreationStep2;
  /* Savoir si la validation en attente pour l'email */
  private addressMailValidationRequested: boolean = false;

  // Variable alimenté après création du service mail utilisé pour l'affichage de la 3e étape
  private mailCreate: ICreateMailServiceRequest = {
    address: '',
    webmailActivated: false,
    sslActivated: false,
    contractAccepted: false,
    accountId : -1,
  };

  private userServiceNameCreated!: string;
  private userLogin?: string;

  private created() {
    this.navSteps.currentstep = 2;
    this.navSteps.steps[0].disabled = true;
    this.showModal('modal-traitement-cours');
    this.loadUserInfoFromWebService();
    this.loadPriceForUserService();
  }

  /**
   * Callback utilisé dans l'appele de la création du mail service
   * @param userSrpDto utilisé dans le composant step2
   * @param email information saisie dans le composant step2
   */
  private createMailService(email: IEmailCreation) {
    this.showModal('modal-traitement-cours');
    const mailCreate: ICreateMailServiceRequest = {
      accountId: this.accountId,
      address: email.addressPrefix + email.addressSuffix,
      webmailActivated: email.webmailActive,
      sslActivated: email.smartphoneActive,
      contractAccepted: email.contractAccepted,
    };
    userServicesUtils.activeMailService(mailCreate)
        .then((response: any) => {
          if (response.status !== undefined) {
            this.completeCreationStep3(mailCreate, this.userSrpDto);
            services.loginService.ifCurrentUserReloadStore(this.accountId,
                () => this.hideModal('modal-traitement-cours'));
            if (response.status === 202) {
              this.addressMailValidationRequested = true;
            }
          } else {
            console.warn(response);
            this.hideModal('modal-traitement-cours');
          }
        })
        .catch(((res: any) => {
          console.error(res);
          AlertService.error('Nous avons rencontré une erreur lors de la tentative de création. Veuillez contacter un administrateur.');
          this.hideModal('modal-traitement-cours');
        }));
  }

  private cancelToStep1() {
    services.routageService.goTo('/account/' + this.accountId + '/services');
  }

  /**
   * Changement d'onglet
   * @param newStep
   */
  private updateCurrentStep(newStep: number) {
    this.navSteps.currentstep = newStep;
  }


  private completeCreationStep3(mailCreate: ICreateMailServiceRequest, userSrpDto?: IUserServicePriceResponse) {
    this.mailCreate = mailCreate;
    this.userServiceNameCreated = userSrpDto !== undefined ? userSrpDto.serviceName : '';
    this.updateCurrentStep(3);
  }

  /**
   * Chargement le prix d'un service mail dans la userSrpDto (utile pour étape 2)
   * @private
   */
  private loadPriceForUserService() {
    userCreationService.getPriceForUserServiceMail().then((response: any) => {
      this.userSrpDto = response.data;
    }).catch(((res: any) => {
      console.error(res);
    }));
  }

  /**
   * Appel du webservice permettant l'affichage des informations de l'utilisateur.
   */
  private loadUserInfoFromWebService() {
    userServicesUtils.getAccount(this.accountId)
        .then((response: any) => {
          if (response !== undefined && response.status === 200) {
            const userResponse: IUserResponse = response.data;
            const role: string = userResponse.authorities !== undefined && userResponse.authorities.length > 0 ?
                userResponse.authorities[0] : '';
            const isEntity = role === 'ROLE_ENTITY';
            const mailExtentionGen: string = LoginUtils.getDefaultNamingConventionFromObject(userResponse);
            this.entitySelected = isEntity;
            this.userCreationStep2.updateMailAndSmartphone(mailExtentionGen, !isEntity);
            this.userLogin = UserUtils.findLoginInUserServices(userResponse);
          } else {
            console.warn(response);
          }
        })
        .catch(((res: any) => {
          console.error(res);
        }))
        .finally(() => {
          this.hideModal('modal-traitement-cours');
        });
  }
}
